<ng-template [ngTemplateOutlet]="isPayment || hideHeaderLiveViewer ? paymentTpl : (isMobile && headerLayout !== null) ? mobileTpl:desktopTpl"></ng-template>

<ng-template #paymentTpl>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #versionTpl let-data>
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span translate>version</span>
    </mat-card-title>
    <mat-card-content class="p-15">
      {{ data.prj }}
      {{ data.vrs }}
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #desktopTpl>
  <div (keyup.shift.tab)="version()" class="container-fluid">
    <header *layoutRender="headerLayout"></header>
    <div [class.new-container]="newHeaderLayout !== 'header1'" class="{{newHeaderLayout}}">
      <router-outlet></router-outlet>
    </div>
    <footer *layoutRender="'fullWidth'"></footer>
  </div>
</ng-template>

<ng-template #mobileTpl>
  <mat-drawer-container autosize hasBackdrop="false">
    <mat-drawer mode="over" #drawer position="start">
      <sidebar-menu [isMenuOpened]="drawer.opened"></sidebar-menu>
    </mat-drawer>
    <mat-drawer-content (click)="drawer.close()">
      @if (!hideMobileHeader) {
        <header-mobile [(isMenuOpen)]="drawer.opened"></header-mobile>
      }
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
