import { Routes } from '@angular/router';
import { LangGuard } from '@bs/services/guards/lang.guard';
import { TranslateGuard } from '@bs/services/guards/translate.guard';
import { UserLoggedGuard } from '@bs/services/guards/user-logged.guard';
import { CurrenciesResolver } from '@bs/services/resolvers/core/currencies.resolver';
import { MenuResolver } from '@bs/services/resolvers/core/menu.resolver';
import { SlidersResolver } from '@bs/services/resolvers/core/sliders.resolver';
import { environment } from '../environments/environment';

const optionalRoutes: Routes = [];

optionalRoutes.push({
  path: 'sports',
  loadChildren: () => import('./pages/sports/sports.module').then(m => m.SportsModule)
});

if (environment.api.games) {
  optionalRoutes.push({
    path: 'games',
    loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
  });
}

// if (environment.api.sportsbook) {
//   optionalRoutes.push({
//     path: 'sportsbook',
//     loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
//   });
// }
//
// if (environment.api.boe) {
//   optionalRoutes.push({
//     path: 'sportbook',
//     resolve: {
//       menu: MenuResolver
//     },
//     data: {
//       menuId: BartMenu.Sportsbook
//     },
//     children: [
//       {
//         path: '',
//         pathMatch: 'full',
//         redirectTo: 'prematch'
//       },
//       {
//         path: '',
//         loadChildren: () => import('./pages/sport-live/sport-live.module').then(m => m.SportLiveModule)
//       }
//     ]
//   })
// }

if (environment.api.promo) {
  optionalRoutes.push({
    path: 'promotions',
    resolve: {
      menu: MenuResolver
    },
    data: {
      section: 'promotions',
      menuId: null
    },
    loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
  });
}

if (environment.api.cms) {
  optionalRoutes.push({
    path: 'cms',
    data: {
      section: 'cms'
    },
    loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
  });
}

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    resolve: {
      currencies: CurrenciesResolver,
      sliders: SlidersResolver
    },
    data: {
      langs: environment.langs
    },
    canActivate: [LangGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.homePage
      },
      ...optionalRoutes,
      {
        path: 'landing',
        loadComponent: () => import('./pages/core/landing/landing.page').then(c => c.LandingPage),
        title: 'seo-landing-page'
      },
      {
        path: 'login',
        loadComponent: () => import('./pages/core/login/login.page').then(c => c.LoginPage),
        title: 'seo-login'
      },
      {
        path: 'register',
        title: 'seo-register',
        loadComponent: () => import('./pages/core/register/register.page').then(c => c.RegisterPage)
      },
      {
        path: 'agent-login',
        loadComponent: () => import('./pages/core/agent-login/agent-login.page').then(c => c.AgentLoginPage)
      },
      {
        path: 'accounts',
        loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'me',
        loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
      },
      {
        path: 'email-confirmation',
        canActivate: [UserLoggedGuard],
        loadComponent: () => import('./pages/core/email-confirmation/email-confirmation.page').then(c => c.EmailConfirmationPage)
      },
      {
        path: 'rp',
        canActivate: [UserLoggedGuard],
        loadComponent: () => import('./pages/core/password-recovery/password-recovery.page').then(c => c.PasswordRecoveryPage)
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/core/payment/payment.module').then(m => m.PaymentModule)
      },
      // {
      //   path: 'live-viewer',
      //   data: {
      //     hideHeaderLiveViewer: true
      //   },
      //   loadChildren: () => import('./pages/live-viewer/live-viewer.module').then(m => m.LiveViewerModule)
      // },
      {
        path: 'error',
        loadChildren: () => import('./pages/core/errors/error-pages.module').then(m => m.ErrorPagesModule)
      },
      {
        path: '**',
        redirectTo: 'error/404'
      }
    ]
  }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//     scrollPositionRestoration: 'enabled',
//     //relativeLinkResolution: 'legacy',
//     initialNavigation: 'enabledBlocking'
//   })],
//   exports: [RouterModule]
// })
// export class AppRoutes {
// }
