<mat-nav-list>
  @for (lang of languages; track lang.code) {
    <mat-list-item class="menu-item-languages" [class.active]="lang.code === currentLang.code">
      <a (click)="changeLanguage(lang.code)" >
        <div class="flag-rounded sidebar-flag-icon d-flex align-items-center justify-content-center me-2">
          <svg [icon]="lang.code | lowercase | slice:-2" lib="flags" [size]="{w:35}" class="flex-shrink-0 h-100"></svg>
        </div>
        <span class="sidebar-flag-name">&nbsp;{{lang.name}}</span>
      </a>
    </mat-list-item>
  }
</mat-nav-list>
