import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

/**
 * LangGuard is a route guard that checks if the requested language is supported.
 *
 * @description
 * This guard serves the following purposes:
 * 1. It verifies if the language specified in the route parameters exists in the list of supported languages.
 * 2. If the language is supported, it allows navigation to proceed.
 * 3. If the language is not supported, it redirects to a 404 page using either the default language or 'en'.
 */
export const LangGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const router = inject(Router);
  const translate = inject(TranslateService);

  const ican = route.data.langs.some(l => l.code === route.params.lang);
  const lang = translate.currentLang || 'en';
  return ican || router.navigate([lang, '404']);
};
