import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

interface Operation {
  action: 'navigate' | 'login' | 'logout',
  variables: any;
}

@Injectable()
export class SeamlessService {

  constructor(private config: EnvironmentConfig, private router: Router, private translate: TranslateService, private auth: AuthService) {
  }

  perform(operation: MessageEvent<Operation>) {

    switch (operation.data.action) {
      case 'navigate':
        this.router.navigate([`${this.translate.currentLang}/${operation.data.variables.path}`]);
        break;
      case 'login':
        this.auth.login(operation.data.variables).subscribe({
          next: me => operation.source.postMessage({action: 'logged', me}, {targetOrigin: this.config.seamless}),
          error: error => operation.source.postMessage({action: 'error', error}, {targetOrigin: this.config.seamless})
        });
        break;
      case 'logout':
        this.auth.logout('manual');
        operation.source.postMessage({action: 'logout'}, {targetOrigin: this.config.seamless})
        break;
      default:
        throw new Error('cannot identify proper action');
    }
  }
}
