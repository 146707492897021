import { CurrencyPipe } from '@angular/common';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { coreProviders } from '@bs/common/core.providers';
import { TranslationModule } from '@bs/common/translation.module';
import { universalProviders } from '@bs/universal/universal.providers';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { appRoutes } from './app-routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslationModule.forRoot()
    ),
    coreProviders(environment),
    universalProviders(),
    provideRouter(appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'})
    ),
    provideServiceWorker('ngsw-worker.js', {enabled: !isDevMode() && !environment.mobileApp, registrationStrategy: 'registerWhenStable:30000'}),
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      useFactory: () => inject(TranslateService).currentLang
    },
    provideAnimations()
  ]
};
