import { LowerCasePipe, SlicePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatListItem, MatNavList } from '@angular/material/list';
import { Router } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { TranslateService } from '@ngx-translate/core';

interface ILangCode {
  name: string;
  code: string;
}

@Component({
  selector: 'sidebar-menu-languages',
  templateUrl: './sidebar-menu-languages.component.html',
  standalone: true,
  imports: [MatNavList, MatListItem, LowerCasePipe, SlicePipe, SvgIconDirective]
})
export class SidebarMenuLanguagesComponent {
  languages = this.config.langs;
  currentLang: ILangCode = { name: '', code: this.config.defaultLangCode };

  constructor(private config: EnvironmentConfig, private translate: TranslateService, private appSettingsService: AppSettingsService, private router: Router) {
    this.currentLang = this.languages.find(x => x.code === translate.currentLang);
    this.appSettingsService.saveSetting('languageCode', translate.currentLang);
  }

  changeLanguage(lang: string) {
    const asAray = this.router.url.split('/');
    const last = asAray.splice(2, asAray.length - 2).join('/');
    void this.router.navigateByUrl(`/${lang}/${last}`);
    this.translate.use(lang);
    this.appSettingsService.saveSetting('languageCode', lang);
  }
}
