<ng-template #userLinkTpl>
  <li (click)="toDeposit()">
    <svg [size]="{w:17, h:17}" icon="bank" lib="global"></svg>
  </li>
  <li (click)="reloadBalance()">
    <svg [size]="{w:18, h:18}" icon="refresh" lib="global"></svg>
  </li>
  <li>
    <a [routerLink]="[]" fragment="settings">
      <svg [size]="{w:17, h:17}" icon="cog" lib="global"></svg>
    </a>
  </li>
  @if (me) {
    <li (click)="logout()">
      <svg icon="log-out" lib="global" [size]="{w:18, h:18}"></svg>
    </li>
  }
</ng-template>

<ng-template #guestLinkTpl>
  <li>
    <a [routerLink]="[]" fragment="login">
      <svg [size]="{w:18, h:18}" icon="log-in" lib="global"></svg>
    </a>
  </li>
  <li>
    <a [routerLink]="[]" fragment="settings">
      <svg [size]="{w:17, h:17}" icon="cog" lib="global"></svg>
    </a>
  </li>
  <li [class.active-menu]="showMenuLanguages">
    <a (click)="showMenuLanguages=!showMenuLanguages" class="h-100 d-flex align-items-center">
      <div class="flag-rounded sidebar-flag-icon d-flex align-items-center justify-content-center me-2">
        <svg [icon]="currentLang.code | lowercase | slice:-2" [size]="{w:35, h:35}" class="flex-shrink-0 h-100" lib="flags"></svg>
      </div>
    </a>
  </li>
</ng-template>


<div class="sidebar-container">
  <div [ngClass]="{logged:!!me}" class="top-box row gx-0 d-flex align-items-center">
    <div class="col-6">
      @if (me) {
        <div class="account-info-container d-flex flex-column align-items-center">
          <div class="flex-fill balance-container">{{ me.id }}</div>
          <div class="flex-fill username-container">{{ me.username }}</div>
          <div class="flex-fill balance-container">
            {{ totalBalance | moneyFormat:me.currencies[0]:true }}
          </div>
        </div>
      }
    </div>
    <div class="col-6 h-100 d-flex align-items-center justify-content-end">
      <ul class="actions-container">
        <ng-template [ngTemplateOutlet]="me ? userLinkTpl: guestLinkTpl"></ng-template>
      </ul>
    </div>
  </div>
  <div class="menu-container">
    @if (!showMenuLanguages) {
      @if (me) {
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <span [translate]="'account'">Account</span>
            </ng-template>
            <mat-nav-list>
              <menu [menu]="accountMenu" class="side-left"></menu>
            </mat-nav-list>
          </mat-tab>
        </mat-tab-group>
      } @else {
        <a [routerLink]="'/en/games/crash-games'"><img src="assets/images/crash-games-banner.webp" [width]="300" [height]="100"></a>
        <a [routerLink]="'/en/games/casino'"><img src="assets/images/casino-banner.webp" [width]="300" [height]="100"></a>
        <a [routerLink]="'/en/sports'"><img src="assets/images/sportsbook-banner.webp" [width]="300" [height]="100"></a>
      }
    } @else {
      <sidebar-menu-languages></sidebar-menu-languages>
    }
  </div>
</div>
