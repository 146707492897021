import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, HostBinding, HostListener, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { LayoutRenderDirective } from '@bs/common/directives/layout-render.directive';
import { debounce } from '@bs/common/helpers/decorators';
import { getLeafRoute } from '@bs/common/helpers/get-leaf-route';
import { AppSettings } from '@bs/models/common/app-settings';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CatalogService } from '@bs/services/services/core/catalog.service';
import { CookieService } from '@bs/services/services/core/cookie.service';
import { CordovaService } from '@bs/services/services/core/cordova.service';
import { DynamicScriptLoaderService } from '@bs/services/services/core/dynamic-script-loader.service';
import { SeamlessService } from '@bs/services/services/core/seamless.service';
import { LocalStorage } from '@bs/universal/universal.providers';
import { WINDOW, WindowService } from '@bs/universal/window.service';
import { FooterComponent } from '@homer/common/components/footer/footer.component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';

@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterOutlet,
    MatCard,
    MatCardTitle,
    MatCardContent,
    SidebarMenuComponent,
    MatDrawer,
    MatDrawerContainer,
    HeaderMobileComponent,
    LayoutRenderDirective,
    NgTemplateOutlet
  ],
  standalone: true
})
export class AppComponent implements OnInit, AfterViewInit {
  isMobile: boolean;
  settings: AppSettings;
  hideMobileHeader: boolean;
  hideHeaderLiveViewer: boolean;
  routeChange$: Subscription;
  isPayment: boolean;
  //sportsBookEnabled: boolean;
  headerLayout = environment.layout?.header;
  newHeaderLayout = environment.features?.headerLayout || 'header1';

  @HostBinding('class.light')
  light: boolean;

  @HostBinding('class.seamless')
  isSeamless = !!environment.seamless;

  @HostBinding('class')
  deviceClass = '';

  @ViewChild('versionTpl', { static: true, read: TemplateRef })
  versionTpl: TemplateRef<any>;

  @ViewChild(MatDrawer, { static: false })
  drawer: MatDrawer;

  constructor(@Inject(WINDOW) window: Window,
              private windowService: WindowService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private dialog: MatDialog,
              private router: Router,
              private cookieService: CookieService,
              meta: Meta,
              swUpdate: SwUpdate,
              snackbar: MatSnackBar,
              appSettingsService: AppSettingsService,
              private catalogService: CatalogService, translate: TranslateService,
              private localStorage: LocalStorage,
              dsl: DynamicScriptLoaderService,
              @Optional() private seamlessService: SeamlessService,
              @Optional() cordovaService: CordovaService
  ) {
    meta.updateTag({ name: 'copyright', content: `${environment.project.split('.')[0]} - ${environment.appVersion}` });

    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe({
        next: () => this.authService.isLogged() || window.location.reload()
      });

    // if (environment.api.sportsbook) {
    //   this.sportsBookEnabled = true;
    // }

    if (environment.production && !environment?.mobileApp) {
      void swUpdate.checkForUpdate();
    }

    route.fragment.pipe(filter(fragment => !!fragment)).subscribe({
      next: (fragment: string) => {
        switch (fragment) {
          case 'logout':
            this.authService.logout();
            break;
          case 'login': {
            const currentNavigation = router.getCurrentNavigation();

            import('@homer/common/dialogs/login/login-dialog.component').then(d => {
              dialog.open(d.LoginDialog, {
                disableClose: true,
                panelClass: 'dialog-login'
              }).afterClosed().subscribe({
                next: res => {
                  if (res) {
                    const commands = [];
                    if (router.url.includes('register') || router.url.includes('email-confirmation')) {
                      commands.push('/');
                    } else if (currentNavigation.extras.state) {
                      commands.push(currentNavigation.extras.state.redir);
                    }
                    void router.navigate(commands, { fragment: null });
                  }
                }
              });
            });
            break;
          }
          case 'register':
            import('@homer/common/dialogs/registration/registration-dialog.component').then(d => {
              dialog.open(d.RegistrationDialog, {
                width: '400px',
                disableClose: true
              });
            });

            break;
          case 'settings':
            import('@homer/common/dialogs/settings/settings-dialog.component').then(d => {
              dialog.open(d.SettingsDialog, {
                disableClose: true
              });
            });

            break;
        }
      }
    });

    appSettingsService.appSettings$.subscribe({
      next: ({ settings, valueChanged }) => {
        this.settings = settings;

        this.settings.displayCurrency = this.catalogService.currentCurrency;

        this.light = !settings.darkTheme;

        if (this.routeChange$) {
          this.routeChange$.unsubscribe();
        }

        this.routeChange$ = router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(
            {
              next: (value: NavigationEnd) => {

                this.isPayment = value.url.includes('payment/response') || value.url.includes('payment/success') || value.url.includes('payment/declined');

                const promo = this.route.snapshot.queryParams.promoCode;
                if (promo) {
                  this.localStorage.setItem('promoCode', promo);
                }
              }
            }
          );

        if ([null, 'timeZone', 'languageCode'].includes(valueChanged)) {
          const scripts = environment.externalScripts as any;
          const rooted = scripts.flatMap(link => link?.inRoot ? [link.name] : []);
          dsl.load(...rooted).forEach(re => {
            re.subscribe(tag => {
              if (tag.options?.callback) {
                tag.options.callback({ settings });
              }
            });
          });
        }

      }
    });

    if (cordovaService) {
      cordovaService.notifications.subscribe({
        next: notification => {
          snackbar.open(translate.instant(notification.title), translate.instant('close'), { panelClass: 'warning' });
        }
      });
    }

  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin === environment.seamless) {
      this.seamlessService.perform(event);
    }
  }

  @HostListener('window:resize')
  @debounce(50)
  onResize() {
    this.deviceClass = `${this.windowService.detectDevice()} ${this.settings.languageCode}`;
  }

  ngOnInit() {
    this.isMobile = this.windowService.device$.getValue().isMobile;

    this.windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    });

    this.authService.isLogged();

    this.route.queryParamMap.subscribe({
      next: params => {
        const sessionId = params.get('sessionId');
        const recoveryToken = params.get('recoveryToken');
        //const ticketCode = params.get('ticketCode');

        if (params.has('i')) {
          this.authService.loginToken(params.get('i')).finally(() => this.router.navigate([], { queryParams: { t: null } }));
        } else if (sessionId) {
          this.authService.token = sessionId;
          // this.authService.guessAuthentication();
        } else if (recoveryToken) {
          import('@homer/common/dialogs/password-recovery/password-recovery-dialog.component').then(d => {
            this.dialog.open(d.PasswordRecoveryDialog, {
              width: this.isMobile ? '' : '400px',
              data: {
                token: recoveryToken,
                mode: 'password'
              }
            });
          });

        }
        // else if (ticketCode) {
        //   this.dialog.open(TicketDetailsDialog, {
        //     data: {code: ticketCode}
        //   });
        // }

        ['affiliateId', 'referralCode'].forEach(key => {
          if (params.has(key)) {
            if (environment.production) {
              this.cookieService.set(key, params.get(key), 365, '/', `.${environment.project}`, true); // set tracking cookies expires 1 year
            } else {
              this.cookieService.set(key, params.get(key), 1, '/');
            }
          }
        });
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router.routerState.root),
        map(r => getLeafRoute(r))
      )
      .subscribe({
        next: route => {
          this.hideMobileHeader = this.isMobile && (route.data as BehaviorSubject<any>).value.hideMobileHeader;
          this.hideHeaderLiveViewer = (route.parent.data as BehaviorSubject<any>).value.hideHeaderLiveViewer;
          void this.drawer?.close();
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.deviceClass = `${this.windowService.detectDevice()} ${this.settings.languageCode}`;
    }, 10);
  }

  version() {
    this.dialog.open(this.versionTpl, {
      data: { vrs: environment.appVersion, prj: environment.project.split('.')[0] },
      width: '300px'
    });
  }
}
