import { Component } from '@angular/core';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { LocalizedDatePipe } from '@bs/common/pipes/localized-date.pipe';

@Component({
  templateUrl: './clock.component.html',
  selector: 'clock',
  styles: [`:host {
    display: block;
  }`],
  imports: [
    LocalizedDatePipe,
    SvgIconDirective
  ],
  standalone: true
})
export class ClockComponent {

  currentTime = new Date();

  constructor() {
    setInterval(() => this.currentTime = new Date(), 1000);
  }
}
