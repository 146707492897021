import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BartMenu } from '@bs/models/cms/enumMenu';
import { IMenuItem } from '@bs/models/cms/menu';
import { AppSettings } from '@bs/models/common/app-settings';
import { IMe } from '@bs/models/me/me';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { MenuComponent } from '@homer/common/components/menu/menu.component';

import { Subscription } from 'rxjs';

@Component({
  selector: 'account-menu',
  templateUrl: 'account-menu.component.html',
  standalone: true,
  imports: [
    MenuComponent
  ]
})

export class AccountMenuComponent implements OnDestroy {

  @Input() me: IMe;
  menu: IMenuItem;

  settings: AppSettings;

  @Output() menuChange = new EventEmitter<IMenuItem>();

  subs = new Subscription();

  constructor(appSettingsService: AppSettingsService, private cmsService: CmsService) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings }) => {
        this.settings = settings;
        /*if ([null, 'timeZone', 'languageCode'].includes(valueChanged)) {
        }*/
        this.subs.add(this.cmsService.getMenu(BartMenu.MeNavigation).subscribe({
            next: menu => {
              this.menu = menu;
              this.menuChange.emit(this.menu);
            }
          }
        ));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /*setSelected(link: ICmsMenuLink) {
    // this.menu.links.forEach(l => l.selected = l.menuLinkId === link.menuLinkId);
    this.menuChange.emit(this.menu);
  }*/
}
