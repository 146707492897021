<ng-template #phoneTpl>
  <!--  <form (ngSubmit)="login(pinForm)" [formGroup]="pinForm" class="d-flex">
  <mobile-prefix [placeholder]="'phone' | translate" class="outline" formControlName="username"></mobile-prefix>
  <mat-form-field appearance="outline" class="ml-1">
    <input [placeholder]="'password' | translate" autocomplete="current-password" formControlName="password" matInput type="password">
    <button (click)="forgotPassword()" mat-icon-button matSuffix tabindex="-1" class="forgot-password-btn p-0" type="button">
      <md-icon icon="help-circle-outline"></md-icon>
    </button>
  </mat-form-field-->
  <button class="ms-2 stroke-primary" (click)="loginModal()" mat-stroked-button type="button">
    @if (isLoading) {
      <mat-spinner [diameter]="22" color="accent"></mat-spinner>
    }
    <span [translate]="'login'">Login</span>
  </button>

</ng-template>

<ng-template #emailTpl>
  <form (ngSubmit)="login(loginForm)" [formGroup]="loginForm" class="d-flex">
    <mat-form-field appearance="outline">
      <input [placeholder]="'username' | translate" autocomplete="username" formControlName="username" matInput
             type="text">
    </mat-form-field>
    <mat-form-field appearance="outline" class="ms-1">
      <input [placeholder]="'password' | translate" autocomplete="current-password" formControlName="password" matInput
             type="password">
    </mat-form-field>
    <button class="ms-2 stroke-primary position-relative" mat-stroked-button type="submit">
      @if (isLoading) {
        <mat-spinner [diameter]="22" color="accent"></mat-spinner>
      }
      <span [translate]="'login'">Login</span>
    </button>
  </form>
</ng-template>

<ng-template #guestTpl>
  <li>
    <ng-container [ngTemplateOutlet]="defaultRegistration.includes('phone') ? phoneTpl:emailTpl"></ng-container>
  </li>
  <li>
    @if (!hideRegistration) {
      <button [routerLink]="['/', settings.languageCode, 'register']" class="register-btn"
              mat-flat-button type="button"><span
        [translate]="'register'">Register</span>
      </button>
    }
  </li>
</ng-template>

<ng-template #loggedH4Tpl>
  <div class="logged-area d-flex">
    <li class="logged-actions d-flex">
      <div class="d-flex user-info align-items-center">
        @if (showBalance) {
          <span class="m-r-10">{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
          <button (click)="reloadBalance()" class="me-2 p-0" mat-button type="button">
            <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
          </button>
        }
        <button (click)="showBalance = !showBalance" class="p-0" mat-button type="button">
          @if (showBalance) {
            <svg icon="eye-off-outline" lib="global" [size]="{w:18, h:18}"></svg>
          } @else {
            <svg icon="eye-outline" lib="global" [size]="{w:18, h:18}"></svg>
          }
        </button>
      </div>
      <a mat-flat-button class="register-btn deposit d-flex"
         routerLink="/{{settings.languageCode}}/{{config.depositPage}}">
        <span [translate]="'deposit'">Deposit</span>
      </a>
    </li>
    <li class="logged-actions">
      <button mat-button [matMenuTriggerFor]="userMenu" mat-flat-button class="menu-account h-100">
        <svg icon="account" lib="global" [size]="{w:15, h:15}"></svg>
      </button>

      <mat-menu #userMenu="matMenu" class="account-menu">
        <account-menu (menuChange)="setAccountMenu($event)" [me]="me"></account-menu>
      </mat-menu>
    </li>
    <li class="logged-actions">
      <button (click)="logout()" mat-button type="button" mat-flat-button class="menu-account h-100">
        <svg icon="log-out" lib="global" [size]="{w:18, h:18}"></svg>
      </button>
    </li>
  </div>
</ng-template>

<ng-template #defaultHeader>
  <ng-template #leftMenuGuestTpl>
    <div class="d-flex align-items-center justify-content-end p-r-10">
      <clock *ssr-norender class="d-flex align-items-center"></clock>
      <button [routerLink]="[]" fragment="login" class="mx-2 stroke-primary" mat-stroked-button type="button">
        <span translate>login</span>
      </button>
      @if (!hideRegistration) {
        <button [routerLink]="['/', settings.languageCode, 'register']" class="register-btn"
                mat-flat-button type="button">
          <span translate>register</span>
        </button>
      }
    </div>
  </ng-template>

  <ng-template #leftMenuLoggedTpl let-me>
    <div class="actions-container d-flex justify-content-end">
      <ng-template [ngTemplateOutletContext]="{$implicit:me}" [ngTemplateOutlet]="loggedTpl"></ng-template>
    </div>
  </ng-template>

  <ng-template #layoutMenuTpl>
    @switch (layoutRender) {
      @case ('left') {
        <div class="row gx-0 second left-layout" [class.logged]="me">
          <a class="logo-container" routerLink="/{{settings.languageCode}}/{{homePage}}">
            <div class="bck"></div>
          </a>
          <menu [menu]="mainMenu" class="menu-container text-center inline align-self-end"></menu>
          <ng-container [ngTemplateOutletContext]="{$implicit:me}"
                        [ngTemplateOutlet]="me ? leftMenuLoggedTpl : leftMenuGuestTpl"></ng-container>
        </div>
      }
      @case ('right') {
        <!-- TODO right layout to be defined -->
        <div class="row second gx-0 d-flex justify-content-between align-items-end">
          <a class="logo-container" routerLink="/{{settings.languageCode}}/{{homePage}}">
            <div class="bck"></div>
          </a>
          <menu [menu]="mainMenu" class="menu-container text-center inline"></menu>
        </div>
      }
      @default {
        <div class="form-row first m-0">
          <clock *ssr-norender class="col-4 d-flex align-items-center"></clock>
          <ul class="col-8 actions-container d-flex justify-content-end">
            @if (hasChat) {
              <li>
                <mobile-chat-btn></mobile-chat-btn>
              </li>
            }
            <ng-template [ngTemplateOutletContext]="{$implicit:me}"
                         [ngTemplateOutlet]="me ? loggedTpl :guestTpl"></ng-template>
          </ul>
        </div>
        <div class="row second gx-0 align-items-end">
          <a class="col-2 logo-container" routerLink="/{{settings.languageCode}}/{{homePage}}">
            <div class="bck"></div>
          </a>
          <menu [menu]="mainMenu" class="col-10 menu-container text-center inline"></menu>
        </div>
      }
    }
  </ng-template>

  <ng-template #loggedTpl let-me>
    <li class="logged-actions">
      <a [matMenuTriggerFor]="userMenu" class="username d-flex align-items-center" href="javascript:">
        <svg icon="account" lib="global" [size]="{w:15, h:15}"></svg>
        <span class="ms-2 me-1">{{ me.id }}</span>
        <span class="ms-2 me-1">{{ me.username }}</span>
        <svg icon="menu-down" lib="global" [size]="{w:20, h:20}"></svg>
      </a>

      <mat-menu #userMenu="matMenu" class="account-menu">
        <account-menu (menuChange)="setAccountMenu($event)" [me]="me"></account-menu>
      </mat-menu>

    </li>
    @if (me.typeId === 0) {
      <li class="logged-actions">
        @if (showBalance) {
          <button (click)="reloadBalance()" class="me-2 p-0" mat-button type="button">
            <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
          </button>
          <span class="m-r-10">{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
        }
        <button (click)="showBalance = !showBalance" class="p-0" mat-button type="button">
          @if (showBalance) {
            <svg icon="eye-off-outline" lib="global" [size]="{w:18, h:18}"></svg>
          } @else {
            <svg icon="eye-outline" lib="global" [size]="{w:18, h:18}"></svg>
          }
        </button>
      </li>
      <li class="logged-actions">
        <a mat-flat-button class="register-btn" routerLink="/{{settings.languageCode}}/{{config.depositPage}}">
          <span [translate]="'deposit'">Deposit</span>
        </a>
      </li>
      @if (permissions.canViewPromotionsPage | hasPermission) {
        <li class="logged-actions">
          <a class="p-0" mat-button routerLink="/{{settings.languageCode}}/promotions">
            <svg icon="gift-outline" lib="global" [size]="{w:18, h:18}"></svg>
          </a>
        </li>
      }
    }
    @if (me.typeId === 30) {
      <li class="logged-actions">
        @if (showBalance) {
          <button (click)="reloadBalance()" class="me-2 p-0" mat-button type="button">
            <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
          </button>
          <span class="m-r-10">{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
        }
        <button (click)="showBalance = !showBalance" class="p-0" mat-button type="button">
          @if (showBalance) {
            <svg icon="eye-off-outline" lib="global" [size]="{w:18, h:18}"></svg>
          } @else {
            <svg icon="eye-outline" lib="global" [size]="{w:18, h:18}"></svg>
          }
        </button>
      </li>
    }
    <li class="logged-actions">
      <button (click)="logout()" class="p-0" mat-button type="button">
        <svg icon="log-out" lib="global" [size]="{w:18, h:18}"></svg>
      </button>
    </li>
  </ng-template>

  <div class="header-container">
    <ng-container [ngTemplateOutlet]="layoutMenuTpl"></ng-container>
    <div class="row third gx-0 justify-content-between">
      <menu [menu]="sectionMenu" class="submenu-container inline col-6"></menu>
      <ul class="col-6 d-flex align-items-center settings-container justify-content-end">
        @if (allowThemeSwitch) {
          <li class="padded">
            <theme-switcher></theme-switcher>
          </li>
        }
        <li>
          <dropdown-timezones></dropdown-timezones>
        </li>
        <li>
          <dropdown-language></dropdown-language>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #header4Tpl>
  <div class="new-container header-new">
    <div class="row align-items-center h-100">
      <div class="col">
        <a class="logo-container d-block" routerLink="/{{settings.languageCode}}/{{homePage}}">
          <div class="bck"></div>
        </a>
      </div>
      <div class="col">
        <ul class="actions-container d-flex justify-content-end">
          <ng-template [ngTemplateOutletContext]="{$implicit:me}"
                       [ngTemplateOutlet]="me ? loggedH4Tpl :guestTpl"></ng-template>
        </ul>
      </div>
    </div>
  </div>
  <div class="header-new-menu">
    <div class="new-container h-100">
      <div class="row h-100">
        <div class="col">
          <menu [menu]="mainMenu" class="new-menu text-center inline align-self-end"></menu>
        </div>
        <div class="col">
          <div class="d-flex align-items-center settings-container justify-content-end h-100 p-r-5">
            @if (allowThemeSwitch) {
              <div class="padded">
                <theme-switcher></theme-switcher>
              </div>
            }
            <dropdown-timezones class="h-100 d-flex"></dropdown-timezones>
            <dropdown-language class="h-100 d-flex"></dropdown-language>
            <clock *ssr-norender class="ms-3"></clock>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loggedH3Tpl>
  <div class="logged-area d-flex">
    <li class="logged-actions d-flex">
      <div class="d-flex user-info align-items-center" [class.active]="showBalance">
        @if (showBalance) {
          <button (click)="reloadBalance()" class="p-0" mat-button type="button">
            <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
          </button>
          <span class="mx-2">{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
        }
        <button (click)="showBalance = !showBalance" class="p-0" mat-button type="button">
          @if (showBalance) {
            <svg icon="eye-off-outline" lib="global" [size]="{w:18, h:18}"></svg>
          } @else {
            <svg icon="eye-outline" lib="global" [size]="{w:18, h:18}"></svg>
          }
        </button>
      </div>
      <a mat-flat-button class="register-btn d-flex" routerLink="/{{settings.languageCode}}/{{config.depositPage}}">
        <span [translate]="'deposit'">Deposit</span>
      </a>
    </li>
    <li class="logged-actions d-flex">
      <a [matMenuTriggerFor]="userMenu" class="username d-flex align-items-center" href="javascript:">
        <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
        <span class="ms-2 me-1">{{ me.username }}</span>
        <svg icon="menu-down" lib="global" [size]="{w:20, h:20}"></svg>
      </a>

      <mat-menu #userMenu="matMenu" class="account-menu">
        <account-menu (menuChange)="setAccountMenu($event)" [me]="me"></account-menu>
      </mat-menu>
    </li>
  </div>
</ng-template>

<ng-template #header3Tpl>
  <div class="header-new header-new-3">
    <div class="row align-items-center h-100 new-container">
      <div class="col-2">
        <a class="logo-container d-block" routerLink="/{{settings.languageCode}}/{{homePage}}">
          <div class="bck"></div>
        </a>
      </div>
      <div class="col">
        <ul class="actions-container d-flex justify-content-end settings-container align-items-center">
          <ng-template [ngTemplateOutletContext]="{$implicit:me}"
                       [ngTemplateOutlet]="me ? loggedH3Tpl :guestTpl"></ng-template>
          <li>
            <dropdown-timezones class="h-100 d-flex icon-only"></dropdown-timezones>
          </li>
          <li>
            <dropdown-language class="h-100 d-flex icon-only"></dropdown-language>
          </li>
          @if (me) {
            <li class="logged-actions">
              <button (click)="logout()" mat-button type="button" class="menu-account">
                <svg icon="log-out" lib="global" [size]="{w:18, h:18}"></svg>
              </button>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>

  <div class="header-new-menu-3">
    <div class="new-container h-100 d-flex align-items-center justify-content-between">
      <div class="left-side"></div>
      <menu [menu]="mainMenu" class="new-menu text-center inline align-self-end h-100"></menu>
      <clock *ssr-norender></clock>
    </div>
  </div>

  @if (sectionMenu?.items?.length) {
    <div class="header-new-menu-3-submenu">
      <menu [menu]="sectionMenu" class="inline"></menu>
    </div>
  }
</ng-template>

<ng-template #loggedH2Tpl>
  <div class="logged-area d-flex">
    <li class="logged-actions d-flex">
      <div class="d-flex user-info align-items-center" [class.active]="showBalance">
        @if (showBalance) {
          <button (click)="reloadBalance()" class="p-0" mat-button type="button">
            <svg icon="reload" lib="global" [size]="{w:20, h:20}"></svg>
          </button>
          <span class="mx-2">{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
        }
        <button (click)="showBalance = !showBalance" class="p-0" mat-button type="button">
          @if (showBalance) {
            <svg icon="eye-off-outline" lib="global" [size]="{w:18, h:18}"></svg>
          } @else {
            <svg icon="eye-outline" lib="global" [size]="{w:18, h:18}"></svg>
          }
        </button>
      </div>
      <a mat-flat-button class="register-btn d-flex" routerLink="/{{settings.languageCode}}/{{config.depositPage}}">
        <span [translate]="'deposit'">Deposit</span>
      </a>
    </li>
    <li class="logged-actions d-flex">
      <a [matMenuTriggerFor]="userMenu" class="username d-flex align-items-center" href="javascript:">
        <svg icon="accoutn" lib="global" [size]="{w:20, h:20}"></svg>
        <span class="ms-2 me-1">{{ me.username }}</span>
        <svg icon="menu-down" lib="global" [size]="{w:20, h:20}"></svg>
      </a>

      <mat-menu #userMenu="matMenu" class="account-menu">
        <account-menu (menuChange)="setAccountMenu($event)" [me]="me"></account-menu>
      </mat-menu>
    </li>
    <li class="logged-actions">
      <button (click)="logout()" mat-button type="button" mat-flat-button class="menu-account h-100">
        <svg icon="log-out" lib="global" [size]="{w:18, h:18}"></svg>
      </button>
    </li>
  </div>
</ng-template>

<ng-template #header2Tpl>
  <div class="header-new header-new-2">
    <div class="row align-items-center h-100 new-container gx-0">
      <div class="col-2">
        <a class="logo-container d-block" routerLink="/{{settings.languageCode}}/{{homePage}}">
          <div class="bck"></div>
        </a>
      </div>
      <div class="col">
        <ul class="actions-container d-flex justify-content-end settings-container align-items-center">
          <ng-template [ngTemplateOutletContext]="{$implicit:me}"
                       [ngTemplateOutlet]="me ? loggedH2Tpl :guestTpl"></ng-template>
        </ul>
      </div>
    </div>
  </div>

  <div class="header-new header-new-menu-2">
    <div class="new-container h-100">
      <div class="row">
        <div class="col">
          <menu [menu]="mainMenu" class="new-menu text-center inline align-self-end h-100"></menu>
        </div>
        <div class="col">
          <ul class="actions-container d-flex justify-content-end settings-container align-items-center">
            @if (hasChat) {
              <li>
                <mobile-chat-btn></mobile-chat-btn>
              </li>
            }
            @if (allowThemeSwitch) {
              <li>
                <theme-switcher></theme-switcher>
              </li>
            }
            <li>
              <clock *ssr-norender></clock>
            </li>
            <li>
              <dropdown-timezones class="h-100 d-flex icon-only"></dropdown-timezones>
            </li>
            <li>
              <dropdown-language class="h-100 d-flex icon-only"></dropdown-language>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="header-new-menu-sports">
    <div class="scrollbar new-container h-100"> <!--[config]="{suppressScrollX: false}"-->
      @if (sportsMenu?.length) {
        <div class="w-100 scroll-pane h-100">
          <ul class="navbar d-flex h-100">
            @for (sport of sportsMenu; track sport.id) {
              <li class="nav-item h-100">
                <a class="nav-link d-flex align-items-center h-100"
                   routerLink="/{{settings.languageCode}}/sportbook/prematch/{{sport.name | lowercase | sluggify}}"
                   routerLinkActive="active">{{ sport.name }}</a>
              </li>
            }
          </ul>
        </div>
      } @else {
        <menu [menu]="sectionMenu" class="navbar scroll-pane inline w-100 h-100 section-menu"></menu>
      }
    </div>
  </div>

</ng-template>

@switch (headerLayout) {
  @case ('header1') {
    <ng-container [ngTemplateOutlet]="defaultHeader"></ng-container>
  }
  @case ('header2') {
    <ng-container [ngTemplateOutlet]="header2Tpl"></ng-container>
  }
  @case ('header3') {
    <ng-container [ngTemplateOutlet]="header3Tpl"></ng-container>
  }
  @case ('header4') {
    <ng-container [ngTemplateOutlet]="header4Tpl"></ng-container>
  }
}
