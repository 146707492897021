import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { HasPermissionPipe } from '@bs/common/pipes/hasPermission.pipe';
import { MoneyFormatPipe } from '@bs/common/pipes/money-format.pipe';
import { SluggifyPipe } from '@bs/common/pipes/sluggify.pipe';
import { BartMenu } from '@bs/models/cms/enumMenu';
import { ICmsMenuLink, IMenuItem } from '@bs/models/cms/menu';
import { AppSettings } from '@bs/models/common/app-settings';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { IAccountMePermissions } from '@bs/models/me/enumPermission';
import { IMe } from '@bs/models/me/me';
import { registrationMode } from '@bs/models/me/registration';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { IdentityWalletService } from '@bs/services/services/identity/identity.wallet.service';
import { SsrNorenderDirective } from '@bs/universal/ssr-norender.directive';
import { ClockComponent } from '@homer/common/components/clock/clock.component';
import { DropdownLanguageComponent } from '@homer/common/components/dropdown-language/dropdown-language.component';
import { DropdownTimezonesComponent } from '@homer/common/components/dropdown-timezones/dropdown-timezones.component';
import { MenuComponent } from '@homer/common/components/menu/menu.component';
import { MobileChatBtnComponent } from '@homer/common/components/mobile-chat-btn/mobile-chat-btn.component';
import { ThemeSwitcherComponent } from '@homer/common/components/theme-switcher/theme-switcher.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountMenuComponent } from '../menus/account-menu/account-menu.component';

@Component({
  selector: 'header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  imports: [
    MatProgressSpinner,
    MatFormField,
    MoneyFormatPipe,
    SvgIconDirective,
    MatAnchor,
    RouterLink,
    MatButton,
    AccountMenuComponent,
    MatMenu,
    ReactiveFormsModule,
    TranslateModule,
    NgTemplateOutlet,
    MatMenuTrigger,
    ClockComponent,
    SsrNorenderDirective,
    MenuComponent,
    MobileChatBtnComponent,
    HasPermissionPipe,
    ThemeSwitcherComponent,
    DropdownTimezonesComponent,
    DropdownLanguageComponent,
    LowerCasePipe,
    SluggifyPipe,
    RouterLinkActive,
    MatInput
  ],
  standalone: true

})
export class HeaderComponent implements OnInit, OnDestroy {

  @HostBinding('class')
  layoutRender = environment.layout?.header;

  @HostBinding('class')
  submenu3 = '';

  defaultRegistration: registrationMode = environment.features.registrationModes;
  allowThemeSwitch: boolean = environment.features.allowThemeSwitch;
  headerLayout = environment.features?.headerLayout || 'header1';
  hasChat = environment.liveChats?.tawkUrl || environment.liveChats?.liveChatInc;
  homePage = environment.homePage;
  permissions = IAccountMePermissions;

  me: IMe;
  settings: AppSettings;
  mainMenu: IMenuItem;
  showBalance: boolean;
  accountMenu: IMenuItem;
  sectionMenu: IMenuItem;
  pinForm: FormGroup;
  loginForm: FormGroup;
  isLoading = false;
  hideRegistration: boolean;
  subs = new Subscription();
  sportsMenu: ICmsMenuLink[];
  totalBalance = 0;

  constructor(
    fb: FormBuilder,
    appSettingsService: AppSettingsService,
    private dialog: MatDialog,
    private authService: AuthService,
    private identityWalletService: IdentityWalletService,
    //router: Router,
    //sportbookService: SportbookService,
    private cmsService: CmsService,
    public config: EnvironmentConfig
  ) {

    // if (this.headerLayout === 'header2') {
    //   router.events.pipe(
    //     filter(event => event instanceof NavigationEnd),
    //     switchMap(() => {
    //       if (router.url.includes('prematch')) {
    //         return sportbookService.prematchSportsTree$.pipe(filter(x => !!x));
    //       }
    //       return of(null);
    //     })
    //   ).subscribe({
    //     next: (sports) => {
    //       if (sports) {
    //         this.sportsMenu = sports;
    //
    //       } else {
    //         this.sportsMenu = [];
    //       }
    //     },
    //     error: (error) => console.error(error)
    //   });
    // }

    this.loadMenus();

    this.subs.add(this.authService.accountLogged$.subscribe({
      next: (account: IMe) => this.me = account
    }));
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings, valueChanged }) => {
        this.settings = settings;
        if (['timeZone', 'languageCode'].includes(valueChanged)) {
          this.loadMenus();
        }
      }
    }));

    this.pinForm = fb.group({
      username: [''],
      password: ['', Validators.required]
    });

    this.loginForm = fb.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(20)])],
      password: ['', Validators.required]
    });

    this.showBalance = 'showBalance' in environment.features && environment.features['showBalance'];
    this.hideRegistration = 'hideRegistration' in environment.features && environment.features['hideRegistration'];
    this.subs.add(this.cmsService.sectionMenu$.subscribe({
      next: menu => {
        this.sectionMenu = menu;
        if (this.headerLayout === 'header3' && this.sectionMenu?.items?.length) {
          this.submenu3 = 'header-3-items';
        } else {
          this.submenu3 = '';
        }
      }
    }));
  }

  ngOnInit() {
    this.subs.add(this.authService.accountLogged$.subscribe({
      next: me => {
        if (me) {
          this.calculateTotalBalance();
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  logout(): void {
    this.authService.delete().subscribe({});
  }

  loginModal() {
    import('@homer/common/dialogs/login/login-dialog.component').then(d => {
      this.dialog.open(d.LoginDialog, { panelClass: 'dialog-login' });
    });

  }

  login(form: FormGroup): void {

    this.isLoading = true;

    const values = Object.assign({}, form.value);

    if (typeof form.value.username !== 'string') {
      const mobileObj = form.value.username;
      Object.assign(values, { username: `+${mobileObj.prefix.prefix}${mobileObj.phone}` });
    }

    if (values.password === '') {
      this.isLoading = false;
      //this.dialog.open(LoginDialog, { panelClass: 'dialog-login' });
      this.loginModal();
      return;
    }
    this.authService.login(values).subscribe({
      next: response => {
        this.isLoading = false;
        const me = response.me;
        this.calculateTotalBalance();
        if (this.config.features.showNoBalanceDialog && this.totalBalance < 100) {
          import('@homer/common/dialogs/zero-balance/zero-balance.dialog').then(d => {
            this.dialog.open(d.ZeroBalanceDialog, {
              data: { me },
              width: '600px'
            });
          });


        }
      },
      error: error => {
        this.isLoading = false;

        let errorMessage: string;
        switch (error.status.code) {
          case 500:
            errorMessage = error.status.text;
            break;
          default:
            errorMessage = error.message;
        }

        import('@homer/common/dialogs/login/login-dialog.component').then(d => {
          this.dialog.open(d.LoginDialog, {
            panelClass: 'dialog-login',
            data: {
              username: form.value.username,
              errorMessage
            }
          });
        });

      }
    });
  }

  reloadBalance(): void {
    void this.identityWalletService.wallets();
    this.calculateTotalBalance();
  }

  // forgotPassword() {
  //   this.dialog.open(ForgotPasswordDialog);
  // }

  setAccountMenu($event: IMenuItem) { // todo: check if used
    this.accountMenu = $event;
  }

  private loadMenus() {
    this.subs.add(this.cmsService.getMenu(BartMenu.Main, 'main').subscribe({
      next: value => this.mainMenu = value
    }));
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance);
  }

}
