import { LowerCasePipe, NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatNavList } from '@angular/material/list';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { Router, RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { MoneyFormatPipe } from '@bs/common/pipes/money-format.pipe';
import { BartMenu } from '@bs/models/cms/enumMenu';
import { IMenuItem } from '@bs/models/cms/menu';
import { AppSettings } from '@bs/models/common/app-settings';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { IMe } from '@bs/models/me/me';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { MeService } from '@bs/services/services/me/me.service';
import { MenuComponent } from '@homer/common/components/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SidebarMenuLanguagesComponent } from '../sidebar-menu-languages/sidebar-menu-languages.component';

@Component({
  templateUrl: 'sidebar-menu.component.html',
  selector: 'sidebar-menu',
  styles: [`
    .username-container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `],
  standalone: true,
  imports: [RouterLink, NgClass, NgTemplateOutlet, MatTabGroup, MatTab, MatTabLabel, TranslateModule, MatNavList, SidebarMenuLanguagesComponent, LowerCasePipe, SlicePipe, SvgIconDirective, MoneyFormatPipe, MenuComponent]
})
export class SidebarMenuComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  isMenuOpened: boolean;
  accountMenu: IMenuItem;
  me: IMe;
  settings: AppSettings;

  subs = new Subscription();
  totalBalance = 0;

  currentLang: { name: string, code: string } = { name: '', code: this.config.defaultLangCode };
  languages = this.config.langs;
  showMenuLanguages = false;

  constructor(private meService: MeService,
              private authService: AuthService,
              private router: Router,
              appSettingsService: AppSettingsService,
              private cmsService: CmsService,
              private config: EnvironmentConfig) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings }) => {
        this.settings = settings;
        this.currentLang = this.languages.find(x => x.code === settings.languageCode);
      }
    }));

    this.authService.accountLogged$.subscribe({
      next: data => {
        if (data) {
          this.me = data;
          this.cmsService.getMenu(BartMenu.MeNavigation, 'section').subscribe({
            next: menu => {
              this.accountMenu = menu;
            }
          });
          this.calculateTotalBalance();
        } else {
          this.me = null;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isMenuOpened.currentValue) {
      this.showMenuLanguages = false;
    }
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }

  reloadBalance(): void {
    this.meService.getMe();
    this.calculateTotalBalance();
  }

  toDeposit() {
    void this.router.navigate([`${this.settings.languageCode}/${this.config.depositPage}`]);
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance);
  }
}

