<div class="header-mobile-wrapper">
  <div class="header-mobile-container">
    <div class="row gx-0 align-items-center top-bar">
      <div class="col-2 text-left">
        <a (click)="toggleSideNav($event)" class="icon-left">
          <div [ngClass]="{open:isMenuOpen}" class="menu-button">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
      @if (me) {
        <div class="col-7 text-center d-flex align-items-center justify-content-between">
          <div class="icon-right">
            <mobile-chat-btn class="ms-3 me-2"></mobile-chat-btn>
          </div>
          <a class="logo-container mx-auto" routerLink="/{{settings.languageCode}}/{{homePage}}">
            <div class="bck"></div>
          </a>
        </div>
        <div class="col-3 text-right right-col d-flex align-items-center justify-content-end">
          <div class="d-flex flex-column align-items-start me-2">
            <span class="deposit-amount">{{ totalBalance | moneyFormat:me.currencies[0]:true }}</span>
            <a class="deposit" routerLink="/{{settings.languageCode}}/me/balances/deposit">{{ 'deposit' | translate }}</a>
          </div>
          <a class="me-2" routerLink="/{{settings.languageCode}}/me/overview/details">
            <svg icon="account-circle" lib="global" [size]="{w:24, h:24}"></svg>
          </a>
        </div>
      } @else {
        <div class="col-3 text-center d-flex align-items-center justify-content-center">

          <a class="logo-container me-auto" routerLink="/{{settings.languageCode}}/{{homePage}}">
            <div class="bck"></div>
          </a>
        </div>
        <div class="col-7 text-right right-col">
          <button [routerLink]="['/', settings.languageCode, 'register']" class="register-btn"
                  mat-flat-button type="button">
            <span translate>register</span>
          </button>
          <button [routerLink]="[]" fragment="login" mat-stroked-button type="button">
            <span translate>login</span>
          </button>
<!--          <a [routerLink]="[]" fragment="login">-->
<!--            <svg icon="log-in" lib="global" [size]="{w:18, h:18}"></svg>-->
<!--          </a>-->
          <mobile-chat-btn class="me-2"></mobile-chat-btn>
        </div>
      }


    </div>
    <div class="quick-links">
      <menu [menu]="mainMenu" class="inline mobile mb-5"></menu>
    </div>
  </div>
</div>
