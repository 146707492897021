import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { MoneyFormatPipe } from '@bs/common/pipes/money-format.pipe';
import { BartMenu } from '@bs/models/cms/enumMenu';
import { IMenuItem } from '@bs/models/cms/menu';
import { AppSettings } from '@bs/models/common/app-settings';
import { IMe } from '@bs/models/me/me';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { MenuComponent } from '@homer/common/components/menu/menu.component';
import { MobileChatBtnComponent } from '@homer/common/components/mobile-chat-btn/mobile-chat-btn.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';


@Component({
  templateUrl: 'header-mobile.component.html',
  selector: 'header-mobile',
  standalone: true,
  imports: [NgClass, RouterLink, MatButton, TranslateModule, MobileChatBtnComponent, MoneyFormatPipe, SvgIconDirective, MenuComponent]
})
export class HeaderMobileComponent implements OnDestroy {

  @Input()
  isMenuOpen: boolean;
  @Output()
  isMenuOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  mainMenu: IMenuItem;
  settings: AppSettings;
  homePage = environment.homePage;

  subs = new Subscription();
  totalBalance = 0;

  me: IMe;

  constructor(private dialog: MatDialog, appSettingsService: AppSettingsService, private cmsService: CmsService, authService: AuthService) {
    this.loadMenus();
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings, valueChanged }) => {
        this.settings = settings;
        if (['timeZone', 'languageCode'].includes(valueChanged)) {
          this.loadMenus();
        }
      }
    }));
    this.subs.add(authService.accountLogged$.subscribe(
      {
        next: me => {
          if (me) {
            this.me = me;
            this.calculateTotalBalance();
          } else {
            this.me = null;
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleSideNav(event) {
    event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
    this.isMenuOpenChange.emit(this.isMenuOpen);
  }

  search() {
    import('@homer/sport-live/components/search-events-dialog/search-events-dialog.component').then(d => {
      this.dialog.open(d.SearchEventsDialogComponent);
    });

  }

  private loadMenus() {
    this.subs.add(this.cmsService.getMenu(BartMenu.Main, 'main').subscribe({
      next: value => this.mainMenu = value
    }));
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance);
  }
}
